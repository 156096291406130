<template>
  <div class="h100">
    <el-card class="box-card">
      <avue-crud v-bind="bindVal"
                 v-on="onEvent"
                 :page.sync="page"
                 :search.sync="search"
                 :before-open="beforeOpen"
                 @on-load="getList"
                 @row-save="rowSave"
                 @row-update="rowUpdate"
                 ref="crud"
                 v-model="form">
                 <template slot-scope="{row,index,type}" slot="menuForm">
                    <el-button type="primary"
                      v-if="type=='add'"
                      size="small"
                      icon="el-icon-circle-plus-outline"
                      @click="$refs.crud.rowSave()">保存</el-button>
                    <el-button type="primary"
                      v-if="type=='edit'"
                      icon="el-icon-circle-check"
                      size="small"
                    @click="rowUpdateAndGoOn">修改并继续编辑</el-button>
                    <el-button type="primary"
                      v-if="type=='edit'"
                      icon="el-icon-circle-check"
                      size="small"
                      @click="$refs.crud.rowUpdate()">修改并关闭窗口</el-button>
                    <el-button type="default"
                     icon="el-icon-circle-close"
                     size="small"
                     @click="$refs.crud.closeDialog()"
                    >取消</el-button>
                </template>
              <template slot="orgIdSearch" slot-scope="scope">
                <el-select v-model="search.orgId"
                                    clearable
                                    placeholder="请选择组织">
                                    <el-option v-for="(item,index) in orgList" :key="index"
                               :value="item.id"
                                        :label="item.name"
                                        ></el-option>
                            
                          </el-select>
              </template>
              <template slot="commandSearch" slot-scope="scope">
                <!-- @change="choseCommand" -->
                <el-select v-model="search.command"
                        clearable
                        
                        placeholder="请选择命令动作">
                        <el-option v-for="(item, i) in commandList" :key="i"
                            :value="item.identifier"
                            :label="item.desc"
                            ></el-option>
              </el-select>
            </template>
              <template slot="jobOrgDtosForm" slot-scope="scope">
                    <avue-crud 
                        :option="jobOrgDtosOption" 
                        :data="form.jobOrgDtos"
                        @row-save="orgSave"
                        @row-del="orgDel"
                        >
                        <template slot="nameForm"
                                  slot-scope="scope">
                          <el-select v-model="newOrgObj.name"
                                    clearable
                                    @clear="clearOrg"
                                    @change="choseOrg"
                                    placeholder="请选择组织">
                                    <el-option  v-for="(item,index) in orgList" :key="index"
                                        :value="item.id"
                                        :label="item.name"
                                        ></el-option>
                          </el-select>
                        </template>
                        <template slot="codeForm"
                                  slot-scope="scope">
                          <el-input v-model="newOrgObj.code" disabled></el-input>
                        </template>
                    </avue-crud>
              </template>
              <template slot="taskDtosForm" slot-scope="scope">
                    <avue-crud 
                        :option="taskDtosOption" 
                        :data="form.taskDtos"
                        @row-save="taskSave"
                        >
                        <template slot="nameForm"
                                  slot-scope="scope">
                          <el-select v-model="newTaskObj.name"
                                    clearable
                                    filterable
                                    @change="choseTask"
                                    @clear="clearTask"
                                    placeholder="请选择任务">
                                    <el-option 
                              v-for="(item,index) in taskList" :key="index"
                              :value="item.id"
                                        :label="item.name"
                                        ></el-option>
                          </el-select>
                        </template>
                        <template slot="descForm"
                                  slot-scope="scope">
                          <el-input v-model="newTaskObj.desc" disabled></el-input>
                        </template>
                        <template slot="commandDescForm"
                                  slot-scope="scope">
                          <el-input v-model="newTaskObj.commandDesc" disabled></el-input>
                        </template>
                        <template slot="contentForm"
                                  slot-scope="scope">
                          <el-input v-model="newTaskObj.content" disabled></el-input>
                        </template>
                        <template #menu="{size,row,index}">
                          <el-button @click="rowUp(row,index)"
                                    icon="el-icon-top"
                                    type="text"
                                    size="small">上移</el-button>
                          <el-button @click="rowDown(row,index)"
                                    icon="el-icon-bottom"
                                    type="text"
                                    size="small">下移</el-button>
                          <el-button @click="taskDel(row,index)"
                                    icon="el-icon-delete"
                                    type="text"
                                    size="small">删除</el-button>
                        </template>
                    </avue-crud>
              </template>
      </avue-crud>
    </el-card>
  </div>
</template>

<script>
import { list,update,getOrglist } from '@/api/condition2.0/pageJobs.js'
import { list as taskListApi } from '@/api/condition2.0/pageTasks.js'
import { list as getCommandListApi } from '@/api/condition2.0/listCommands.js'
// import { Sortable } from "https://cdn.staticfile.org/Sortable/1.10.0-rc2/Sortable.min.js"
export default window.$crudCommon({
  data(){
    return{
      commandList:[],
      saveDuplicateFlag:false,
      updateDuplicateFlag:false,
      newTaskObj:{
        name:'',
        desc:'',
        command:'',
        commandDesc:'',
        id:'',
        content:''
      },
      newOrgObj:{
        id:'',
        name:'',
        code:''
      },
      taskList:[],
      orgList:[],
      form:{
        taskDtos:[],
        jobOrgDtos:[]
      },
      jobOrgDtosOption:{
        align: 'center',
        headerAlign: 'center',
        menu:true,
        menuWidth:150,
        copyBtn:false,
        editBtn:false,
        column: [{
            label: '组织名称',
            type: 'select',
            prop: 'name',
            width:120,
            span:24,
            formslot: true
        }, {
            label: '组织code',
            prop: 'code',
            span:24,
            formslot: true
        }]},
      taskDtosOption:{
        align: 'center',
        headerAlign: 'center',
        menu:true,
        menuWidth:220,
        copyBtn:false,
        editBtn:false,
        delBtn:false,
        column: [{
            label: '任务名称',
            type: 'select',
            prop: 'name',
            width:120,
            span:24,
            formslot: true
        }, {
            label: '任务描述',
            prop: 'desc',
            span:24,
            formslot: true
        }, {
            label: '命令动作',
            prop: 'commandDesc',
            span:24,
            formslot: true
        }, {
            label: 'content',
            prop: 'content',
            span:24,
            overHidden:true,
            formslot: true
        }]
    }
    }
  },
  components: {
  },
  created(){
    this.getTaskList()
    this.getOrgList()
    this.getCommandList()
  },
  methods: {
    getCommandList(){
      //this.commandList
      getCommandListApi().then(res=>{
        this.commandList = res
        console.log(this.commandList)
      })
    },
    beforeOpen (done, type) {
      if (type == 'add') {
        console.log("adddddddd")
        this.form.taskDtos = [];
        this.form.jobOrgDtos = [];
      }
      done()
    },
    rowSave(row, done, loading){
      if(this.saveDuplicateFlag) return;  
      // 处理taskDtos和jobOrgDtos
      let taskDtos  = this.form.taskDtos.map(item => {return item.id})
      console.log(taskDtos)
      let jobOrgDtos  = this.form.jobOrgDtos.map(item => {return item.id})
      console.log(jobOrgDtos)
      let data = {
        jobId:'',
        jobDesc:this.form.desc,
        jobName:this.form.name,
        orgIds:jobOrgDtos,
        taskIds:taskDtos
      }
      this.saveDuplicateFlag = true;
      update(data).then(res=>{
        this.$message.success('新增成功')
        this.getList();
        done();
        this.saveDuplicateFlag = false;
        this.form.taskDtos = [];
          this.form.jobOrgDtos = [];
      })
    },
    rowUpdateAndGoOn(){
      if(this.updateDuplicateFlag) return;
      // 处理taskDtos和jobOrgDtos
      let taskDtos  = this.form.taskDtos.map(item => {return item.id})
      console.log(taskDtos)
      let jobOrgDtos  = this.form.jobOrgDtos.map(item => {return item.id})
      console.log(jobOrgDtos)
      let data = {
        jobId:this.form.id,
        jobDesc:this.form.desc,
        jobName:this.form.name,
        orgIds:jobOrgDtos,
        taskIds:taskDtos
      }
      this.updateDuplicateFlag = true;
      update(data).then(res=>{
        this.$message.success('编辑成功')
        this.getList();
        this.updateDuplicateFlag = false;
      })
    },
    rowUpdate(row, index, done){
      if(this.updateDuplicateFlag) return;
      // 处理taskDtos和jobOrgDtos
      let taskDtos  = this.form.taskDtos.map(item => {return item.id})
      console.log(taskDtos)
      let jobOrgDtos  = this.form.jobOrgDtos.map(item => {return item.id})
      console.log(jobOrgDtos)
      let data = {
        jobId:this.form.id,
        jobDesc:this.form.desc,
        jobName:this.form.name,
        orgIds:jobOrgDtos,
        taskIds:taskDtos
      }
      this.updateDuplicateFlag = true;
      update(data).then(res=>{
        this.$message.success('编辑成功')
        this.getList();
        
        this.updateDuplicateFlag = false;
        this.form.taskDtos = [];
        this.form.jobOrgDtos = [];
        done();
      })
    },
    rowUp(row, index){
      
      if(index == 0){
        this.$message.warning('已经是第一个任务,不能上移')
        return;
      }

      if(index!=0){
        this.form.taskDtos[index] = this.form.taskDtos.splice(index-1, 1, this.form.taskDtos[index])[0];
      }else{
        this.form.taskDtos.push(this.form.taskDtos.shift());
      }
    },
    rowDown(row, index){
      if(index == this.form.taskDtos.length-1){
        this.$message.warning('已经是最后一个任务,不能下移')
        return;
      }
      if(index!=this.form.taskDtos.length-1){
        this.form.taskDtos[index] = this.form.taskDtos.splice(index+1, 1, this.form.taskDtos[index])[0];
      }else{
        this.form.taskDtos.unshift( this.form.taskDtos.splice(index,1)[0]);
      }
    },
    taskDel(row, index){
      this.form.taskDtos.splice(index,1);
    },
    orgDel(row, index){
      this.form.jobOrgDtos.splice(index,1);
    },
    taskSave(row, done, loading){
      if(this.newTaskObj.name == ''){
        this.$message.warning('请选择任务')
        loading();  
        return;
      }
      this.form.taskDtos.push(this.newTaskObj);
      this.newTaskObj={
        name:'',
        desc:'',
        command:'',
        commandDesc:'',
        id:'',
        content:''
      }
      done()
    },
    orgSave(row, done, loading){
      if(this.newOrgObj.name == ''){
        this.$message.warning('请选择组织')
        loading();
        return;
      }
      // 判断组织是否已存在
      let exitOrgObj = this.form.jobOrgDtos.find(ele => ele.id == this.newOrgObj.id);
      console.log(exitOrgObj)
      if(exitOrgObj){
        this.$message.warning('该组织已存在,请勿重复添加')
        loading();
        return;
      }
      console.log(this.form.jobOrgDtos)
      this.form.jobOrgDtos.push(this.newOrgObj);
      this.newOrgObj={
        id:'',
        name:'',
        code:''
      }
      done()
    },
    clearTask(){
      this.newTaskObj={
        name:"",
        desc:"",
        command:"",
        commandDesc:'',
        id:"",
        content:""
      }
    },
    choseTask(val){
      let findTaskObj = this.taskList.find(ele => ele.id == val);
      this.newTaskObj={
        name:findTaskObj.name,
        desc:findTaskObj.desc,
        command:findTaskObj.command,
        commandDesc:findTaskObj.commandDesc,
        id:findTaskObj.id,
        content:findTaskObj.content
      }
    },
    clearOrg(){
      this.newOrgObj={
        name:"",
        id:"",
        code:""
      }
    },
    choseOrg(val){
      let findOrgObj = this.orgList.find(ele => ele.id == val);
      this.newOrgObj={
        name:findOrgObj.name,
        id:findOrgObj.id,
        code:findOrgObj.code
      }
    },
    getTaskList(){
      taskListApi({page:0,size:5000}).then(res=>{
        let data = res.content;
        this.taskList = data;
        console.log(this.taskList)
      })
    },
    getOrgList(){
      getOrglist().then(res=>{

        let data = res;
        this.orgList = data;
        console.log(this.orgList)
      })
    },
    addgetActive (res) {
      this.form.frameId = this.form.frameId + res.join(',');
    },
    goIframe (item) {
      this.$router({ path: '/build' + item.frameId })
    },
    getList(){
      //console.log(this.page.currentPage-1)
      let serchObj = {jobName:this.search.name,taskName:this.search.taskName,orgId:this.search.orgId,command:this.search.command}
      list({page:this.page.currentPage-1,size:this.page.pageSize,jobName:this.search.name},serchObj).then(res=>{
        console.log(res)
        let listData = res.content;
        this.data = listData;
        this.page.total = res.totalElements;
      })
    }
  }
}, {
  pageSize: 'size',
  pageNum: 'page',
  name: '/condition2.0/pageJobs',
  res: (data) => {
    return {
      total: data.total,
      data: data.records
    }
  }
})
</script>

<style lang="scss" scoped>
</style>